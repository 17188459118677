/* eslint-disable @next/next/no-img-element */
import { Link } from '@/components/link';
import { Button } from '@/components/button/styles.css';
import clsx from 'clsx';
import { useBlockContext } from '@/lib/contexts/block';
import { bgBanner, button, container, imageContainer, subContainer, textContainer, wrapper } from './styles.css';

const HundredYearHomePageBanner: React.FC = () => {
  const { isSameThemeAsPrevious } = useBlockContext();

  return (
    <div
      className={clsx('component content-blocks-item snippet col-12', wrapper, {
        'is-same-theme-as-prev': isSameThemeAsPrevious,
      })}
    >
      <div className={bgBanner}>
        <div className={clsx(container)}>
          <div className={subContainer}>
            <div className={imageContainer}>
              <img src="/images/hundred-year-logo.svg" alt="hundred year RACT logo" />
            </div>

            <div className={textContainer}>
              <span>
                100 Years of RACT, take a look at highlights from the last century and how we marked this milestone.
              </span>
            </div>
          </div>

          <Link
            className={clsx(
              Button({
                color: 'white',
                size: 'sm',
              }),
              button
            )}
            href="https://100.ract.com.au/"
          >
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

export { HundredYearHomePageBanner };
